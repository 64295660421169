<template>
  <div>
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-start pa-5>
          <v-flex xs12>
            <span class="heading">Edit Hospital</span>
          </v-flex>
          <v-flex xs12>
            <span class="subheading">Basic Info</span>
          </v-flex>
          <v-flex xs12>
            <!-- BASIC INFO -->
            <v-layout wrap justify-start pt-2>
              <v-flex xs12 sm6 md6 lg6 class="subheading">
                <span>Name</span>
                <v-text-field outlined v-model="name" dense hide-details>
                </v-text-field>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
                lg6
                pl-lg-2
                pl-sm-2
                pl-md-2
                class="subheading"
              >
                <span>Latitude</span>
                <v-text-field
                  outlined
                  v-model="lat"
                  dense
                  hide-details
                  type="number"
                  class="inputPrice"
                >
                </v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-2>
              <v-flex xs12 sm6 md6 lg6 class="subheading">
                <span>Longitude</span>
                <v-text-field outlined v-model="lon" dense hide-details>
                </v-text-field>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
                lg6
                pl-lg-2
                pl-sm-2
                pl-md-2
                class="subheading"
              >
                <span>District</span>
                <v-text-field outlined v-model="district" dense hide-details>
                </v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-2>
              <v-flex xs12 sm6 md6 lg6 class="subheading">
                <span>State</span>
                <v-text-field outlined v-model="state" dense hide-details>
                </v-text-field>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
                lg6
                pl-lg-2
                pl-sm-2
                pl-md-2
                class="subheading"
              >
                <span>Address</span>
                <v-text-field outlined v-model="address" dense hide-details>
                </v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-2>
              <v-flex xs12 sm6 md6 lg6 class="subheading">
                <span>MobileNo</span>
                <v-text-field
                  outlined
                  v-model="mobileno"
                  dense
                  hide-details
                  type="number"
                  class="inputPrice"
                >
                </v-text-field>
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
                lg6
                pl-lg-2
                pl-sm-2
                pl-md-2
                class="subheading"
              >
                <span>Category</span>
                <v-select
                  outlined
                  v-model="category"
                  dense
                  hide-details
                  :items="categorylist"
                >
                </v-select>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-2>
              <v-flex xs12 sm12 md12 lg12 class="subheading">
                <span>Notes</span>
                <v-text-field outlined v-model="notes" dense hide-details>
                </v-text-field>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-2>
              <v-flex xs12 sm6 md6 lg6>
                <v-btn depressed color="primary" @click="handlesubmit()">
                  <span>Submit</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
      <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      name: "",
      lat: "",
      lon: "",
      district: "",
      state: "",
      address: "",
      mobileno: "",
      category: "",
      categorylist: ["Government", "Private", "Not for profit"],
      notes: "",
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    handlesubmit() {
      // const phoneRegex = /^[0-9]{10}$/;
      // if (!this.name) {
      //   this.msg = "Please Provide Name.";
      //   this.showSnackBar = true;
      //   return;
      // }
      // if (!this.lat) {
      //   this.msg = "Please Provide Latitude.";
      //   this.showSnackBar = true;
      //   return;
      // }
      // if (!this.lon) {
      //   this.msg = "Please Provide Longitude.";
      //   this.showSnackBar = true;
      //   return;
      // }
      // if (!this.district) {
      //   this.msg = "Please Provide District.";
      //   this.showSnackBar = true;
      //   return;
      // }
      // if (!this.state) {
      //   this.msg = "Please Provide State.";
      //   this.showSnackBar = true;
      //   return;
      // }
      // if (!this.address) {
      //   this.msg = "Please Provide Address.";
      //   this.showSnackBar = true;
      //   return;
      // }
      // if (!this.category) {
      //   this.msg = "Please Select Category.";
      //   this.showSnackBar = true;
      //   return;
      // }
      // if (!this.mobileno) {
      //   this.msg = "Please Provide Mobile Number.";
      //   this.showSnackBar = true;
      //   return;
      // }
      // if (!phoneRegex.test(this.mobileno)) {
      //   this.msg = "Please Provide a Valid Mobile Number (10 digits).";
      //   this.showSnackBar = true;
      //   return;
      // }
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/hospital/edit",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          name: this.name,
          lat: this.lat,
          lon: this.lon,
          Districtname: this.district,
          State: this.state,
          address: this.address,
          mobileno: this.mobileno,
          category: this.category,
          notes: this.notes,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
              this.getData();
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/hospital/" + this.$route.query.id,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.name = response.data.data.name;
              this.district = response.data.data.Districtname;
              this.address = response.data.data.address;
              this.state = response.data.data.State;
              this.lat = response.data.data.location[1];
              this.lon = response.data.data.location[0];
              this.mobileno = response.data.data.mobileno;
              this.category = response.data.data.category;
              this.notes = response.data.data.notes;
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
  },
};
</script>
      <style>
.heading {
  font-family: poppinsregular;
  font-size: 20px;
  font-weight: 900;
}
.subheading {
  font-family: poppinsregular;
  font-size: 15px;
  font-weight: 500;
}
.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>